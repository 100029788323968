import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Seo, VSection } from "components/common"

// import Nav from "components/navigation/Nav"

import { useContentfulSection, useMeta, device } from "utils"

const ErrorPage = ({ data: { error } }) => {
  const {
    hero_400: { title, subtitle },
  } = useContentfulSection(error.sections)

  const meta = useMeta(error)

  return (
    <>
      <Seo {...meta} />
      {/* <Nav /> */}

      <Wrap>
        <VSection
          className="messageBox"
          titleAs="h1"
          align="left"
          title={title}
          subtitle={subtitle}
        />
      </Wrap>
    </>
  )
}

const Wrap = styled.div`
  flex: 1;

  /* min-height: calc(var(--vh, 1vh) * 100); */
  display: flex;
  align-items: center;
  justify-content: center;

  height: calc(var(--vh, 1vh) * 100 - 99px);

  @media ${device.laptop} {
    height: calc(var(--vh, 1vh) * 100 - 163px);
  }

  .messageBox {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    .v_tgContainer {
      --tg-max-width: 800px;
      .subtitle {
        white-space: break-spaces;

        a {
          /* color: var(--act-text_light); */
          font-weight: 600;
        }
      }
    }
  }
`

export const query = graphql`
  {
    error: contentfulPage(pageId: { eq: "400" }) {
      ...Page
    }
  }
`

export default ErrorPage
